import React from 'react'

const LogoutIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.04141 7.96662C6.04141 8.12495 5.98307 8.28328 5.85807 8.40828L4.16641 10.1L5.85807 11.7916C6.09974 12.0333 6.09974 12.4333 5.85807 12.675C5.61641 12.9166 5.21641 12.9166 4.97474 12.675L2.84141 10.5416C2.59974 10.3 2.59974 9.89995 2.84141 9.65828L4.97474 7.52495C5.21641 7.28328 5.61641 7.28328 5.85807 7.52495C5.98307 7.64995 6.04141 7.80828 6.04141 7.96662Z'
        fill='#8A96A1'
      />
      <path
        d='M12.4418 10.1001C12.4418 10.4418 12.1585 10.7251 11.8168 10.7251H3.3418C3.00013 10.7251 2.7168 10.4418 2.7168 10.1001C2.7168 9.75843 3.00013 9.4751 3.3418 9.4751H11.8168C12.1668 9.4751 12.4418 9.75843 12.4418 10.1001Z'
        fill='#8A96A1'
      />
      <path
        d='M17.2917 9.99996C17.2917 14.2916 14.2917 17.2916 10 17.2916C9.65833 17.2916 9.375 17.0083 9.375 16.6666C9.375 16.325 9.65833 16.0416 10 16.0416C13.5583 16.0416 16.0417 13.5583 16.0417 9.99996C16.0417 6.44163 13.5583 3.95829 10 3.95829C9.65833 3.95829 9.375 3.67496 9.375 3.33329C9.375 2.99163 9.65833 2.70829 10 2.70829C14.2917 2.70829 17.2917 5.70829 17.2917 9.99996Z'
        fill='#8A96A1'
      />
    </svg>
  );
}

export default LogoutIcon