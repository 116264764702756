import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";

import { useMutation } from "react-query";
import { login } from "../../utils/services/auth.service";

import { ADMIN_USER } from "../../utils/constant/userPrivileges";
import { saveLocalState } from "../../utils/services/localStorage.service";

import Logo from "../../assets/images/logo.svg";
import LoginImage from "../../assets/images/login-image.svg";
import Form from "../../components/Form/Form";
import { isAuthenticated } from "../../utils/services/isAuthenticated.service";
import { useNavigate } from "react-router-dom";

const defaultTheme = createTheme();

export default function Login() {
  const { mutateAsync: authenticateUser, isLoading } = useMutation(login, {
    onSuccess: async (data: any) => {
      await saveLocalState(ADMIN_USER, {
        ...data,
      });

      toast.success(`${data.data.description}`, {
        position: "top-center",
      });

      window.location.href = "/blogs";
    },
    onError: (error: any) => {
      console.log("Errror ", error);
      toast.error("Network Error, please check your network and try again", {
        position: "top-center",
      });
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <img src={Logo} alt="logo" className="pt-[50px] pl-[50px]" />
          <Box
            sx={{
              my: 8,
              mx: 6,
              display: "flex",
              flexDirection: "column",
              mt: 12,
            }}
          >
            <div className="md:w-[60%] m-auto">
              <Typography component="h1" variant="h5" fontWeight="bold">
                Login
              </Typography>
              <Typography mb={4}>
                Welcome back! Please enter your details
              </Typography>
              <Form authenticateUser={authenticateUser} isLoading={isLoading} />
            </div>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={5}
          sx={{
            backgroundImage: ` url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <p className="absolute bottom-[73px] text-[12px] px-[70px]">
            “Ignite Your Imagination. Inspire Your Readers. Illuminate the
            Blogging Journey."
          </p>
        </Grid>
      </Grid>
      <ToastContainer />
    </ThemeProvider>
  );
}
