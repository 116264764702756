import React from "react";

const PublishedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M7.99984 15.6667C7.59984 15.6667 7.20651 15.5733 6.88651 15.3933L3.73318 13.5733C2.42651 12.6933 2.33984 12.5333 2.33984 11.1533V7.84667C2.33984 6.46667 2.42651 6.30667 3.70651 5.44667L6.89318 3.60667C7.52651 3.24 8.47984 3.24 9.11318 3.60667L12.2665 5.42667C13.5732 6.30667 13.6598 6.46667 13.6598 7.84667V11.1533C13.6598 12.5333 13.5732 12.6933 12.2932 13.5533L9.10651 15.3933C8.79318 15.58 8.39318 15.6667 7.99984 15.6667ZM7.99984 4.33333C7.77318 4.33333 7.54651 4.38 7.38651 4.47333L4.23318 6.29333C3.33984 6.9 3.33984 6.9 3.33984 7.84667V11.1533C3.33984 12.1 3.33984 12.1 4.26651 12.7267L7.39318 14.5267C7.71318 14.7133 8.29318 14.7133 8.61318 14.5267L11.7665 12.7067C12.6598 12.1 12.6598 12.1 12.6598 11.1533V7.84667C12.6598 6.9 12.6598 6.9 11.7332 6.27333L8.60651 4.47333C8.45318 4.38 8.22651 4.33333 7.99984 4.33333Z"
        fill="#120051"
      />
      <path
        d="M11.6663 6.08671C11.393 6.08671 11.1663 5.86004 11.1663 5.58671V3.83337C11.1663 2.78004 10.7197 2.33337 9.66634 2.33337H6.33301C5.27967 2.33337 4.83301 2.78004 4.83301 3.83337V5.54004C4.83301 5.81337 4.60634 6.04004 4.33301 6.04004C4.05967 6.04004 3.83301 5.82004 3.83301 5.54004V3.83337C3.83301 2.22004 4.71967 1.33337 6.33301 1.33337H9.66634C11.2797 1.33337 12.1663 2.22004 12.1663 3.83337V5.58671C12.1663 5.86004 11.9397 6.08671 11.6663 6.08671Z"
        fill="#120051"
      />
      <path
        d="M9.0865 12.2533C8.9465 12.2533 8.79983 12.2266 8.65317 12.1666L7.99983 11.9133L7.3465 12.1733C6.99317 12.3133 6.63317 12.28 6.3665 12.0866C6.09983 11.8933 5.95983 11.56 5.97983 11.18L6.01983 10.48L5.57317 9.93996C5.33317 9.63996 5.25317 9.29329 5.35983 8.97329C5.45983 8.65996 5.73317 8.41996 6.09983 8.32662L6.77983 8.15329L7.15983 7.55996C7.5665 6.91996 8.43983 6.91996 8.8465 7.55996L9.2265 8.15329L9.9065 8.32662C10.2732 8.41996 10.5465 8.65996 10.6465 8.97329C10.7465 9.28662 10.6665 9.63996 10.4265 9.93329L9.97983 10.4733L10.0198 11.1733C10.0398 11.5533 9.89983 11.88 9.63317 12.08C9.47317 12.1933 9.2865 12.2533 9.0865 12.2533ZM6.3465 9.29996L6.79317 9.83996C6.9465 10.02 7.03317 10.3066 7.01983 10.54L6.97983 11.24L7.63317 10.98C7.85317 10.8933 8.1465 10.8933 8.3665 10.98L9.01983 11.24L8.97983 10.54C8.9665 10.3066 9.05317 10.0266 9.2065 9.83996L9.65317 9.29996L8.97317 9.12662C8.7465 9.06662 8.5065 8.89329 8.37983 8.69996L8.0065 8.11329L7.6265 8.69996C7.49983 8.89996 7.25983 9.07329 7.03317 9.13329L6.3465 9.29996Z"
        fill="#120051"
      />
    </svg>
  );
};

export default PublishedIcon;
