import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { deleteLocalState } from "../../utils/services/localStorage.service";
import { ADMIN_USER } from "../../utils/constant/userPrivileges";

import Logo from "../../assets/images/logo.svg";
import * as Icons from "../../utils/icons/index";

const { Sider } = Layout;

const { LogoutIcon, BlogIcon, SubscriberIcon } = Icons;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  value: any,
  label: any,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    value,
    label,
    key,
    icon,
    children,
  } as MenuItem;
}

const items = [
  {
    key: "2",
    label: "Blogs",
    icon: <BlogIcon />,
    link: "/blogs",
  },
  {
    key: "3",
    label: "Subscribers",
    icon: <SubscriberIcon />,
    link: "/subscribers",
  },
];

const item: MenuItem[] = [getItem("Log Out", "Log Out", "9", <LogoutIcon />)];

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string>("2");
  const location = useLocation();

  const navigate = useNavigate();

  const handleLogout = () => {
    deleteLocalState(ADMIN_USER);
    navigate("/");
  };

  return (
    <Sider
      theme="light"
    >
      <div className="mb-[30%] mt-[30px] px-[30px]">
        <img src={Logo} alt="logo" />
      </div>
      <div className="demo-logo-vertical" />
      {items.map((item: any) => (
        <div
          key={item.key}
          className={`pl-[24px] mx-[4px] mb-[24px] ${
            location.pathname === item.link ? "bg-[#06001b0d]" : ""
          } rounded-[8px] px-[12px] py-[10px] flex row`}
        >
          {item.icon}
          <NavLink
            to={item.link}
            className={`ml-[10px] text-[14px] hover:text-[#120051] ${
              location.pathname === item.link
                ? "text-[#120051]"
                : "text-[#8A96A1]"
            } font-bold`}
          >
            {item.label}
          </NavLink>
        </div>
      ))}

      <div
        className="mt-[190%] border-t border-r- border-gray-200 w-[95%] m-auto"
        onClick={handleLogout}
      >
        <Menu
          theme="light"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={item}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
