import React, { ReactElement, useContext } from "react";
import { UiContext } from "../../../context/uiContext";
import * as Icons from "../../../utils/icons/index";
// import { Statistics } from "../../../data/statistics";

interface IStatistics {
  id: number;
  icon: () => ReactElement;
  title: string;
  total: number;
}

interface StatiticsProps {
  statistics: IStatistics[];
}

// const items =

const Statitics: React.FC<StatiticsProps> = ({ statistics }) => {
  return (
    <div className="flex flex-row gap-[20px]">
      {statistics.map((item) => {
        const PostIcon = item.icon;
        return (
          <div
            key={item.id}
            style={{
              display: "flex",
              flexDirection: "row",
              rowGap: 20,
              width: 220,
              height: 80,
              backgroundColor: "#FFF",
              borderRadius: 12,
              padding: "21px",
              // background: 'green',
            }}
          >
            <div
              style={{
                background: "var(--grey-50, rgba(6, 0, 27, 0.05))",

                borderRadius: 8,
                padding: 10,
                justifyContent: "center",
                alignItems: "center",
                width: "35px",
                height: "35px",
              }}
            >
              <PostIcon />
            </div>
            <div
              style={{
                marginLeft: "10px",
                // background: 'red',
                width: 100,
              }}
            >
              <h6
                style={{
                  color: "var(--grey-300, rgba(6, 0, 27, 0.60))",
                  fontFamily: "Roboto",
                  fontSize: 8,
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {item.title}
              </h6>
              <h3
                style={{
                  color: "var(--primary-500, #120051)",
                  fontFamily: "Roboto",
                  fontSize: 28,
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                }}
              >
                {item.total}
              </h3>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Statitics;
