import { AxiosRequestConfig } from "axios";
import { ADMIN_USER } from "../constant/userPrivileges";
import { getSavedState } from "../services/localStorage.service";

export const requestInterceptor = (config: any) => {
  const savedState = getSavedState(ADMIN_USER);
  const token = savedState?.data?.data?.access_token;

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };

  return config;
};
