import { FC } from "react";
import type { ColumnsType } from "antd/es/table";
import Search from "../../UiKits/Search";

interface TableHeaderProps {
  setSelectedTable: (name: string) => void;
  selectedTable: string;
  setSearchText: (text: string) => void;
  searchText: string;
  tableTitle: string;
  blogComp?: boolean;
}

interface DataType {
  key: React.ReactNode;
  name: string;
  age: number;
  address: string;
  children?: DataType[];
}

const header: any = [
  {
    title: "All",
    dataIndex: "all",
    key: "all",
  },
  {
    title: "Published",
    dataIndex: "published",
    key: "published",
    width: "12%",
  },
  {
    title: "Draft",
    dataIndex: "draft",
    width: "30%",
    key: "draft",
  },
];

const TableHeader: FC<TableHeaderProps> = ({
  setSelectedTable,
  selectedTable,
  setSearchText,
  searchText,
  tableTitle,
  blogComp,
}) => {
  const heading = header.map((item: any) => (
    <p
      key={item.key}
      className={`ml-[65.5px] cursor-pointer ${
        item.key === selectedTable ? "text-[#120051]" : "text-[#8A96A1]"
      }`}
      onClick={() => setSelectedTable(item.key)}
    >
      {item.title}
    </p>
  ));
  return (
    <div className="mb-[53.5px] mt-[63.5px]">
      <h2 className="text-[22px] font-bold text-[#06001B]">{tableTitle}</h2>
      <div className="flex row justify-between mt-[28.5px]">
        {blogComp && <div className="flex row">{heading}</div>}
        <Search setSearchText={setSearchText} searchText={searchText} />
      </div>
    </div>
  );
};

export default TableHeader;
