import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../../utils/services/isAuthenticated.service";

interface ProtectedRoute {
  component: any;
}

const ProtectedRoute = ({ component }: ProtectedRoute) => {
  const authenticated = isAuthenticated();
  if (!authenticated) {
    return <Navigate to="/" replace={true} />;
  }
  return component;
};

export default ProtectedRoute;
