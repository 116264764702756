import React, { useState, useContext } from "react";

import { Layout, theme } from "antd";

import Sidebar from "../../components/Sidebar/Sidebar";
import DashboardLayout from "../../components/Dashboard/Layout";
import BlogContent from "../../components/Blogs/BlogContent/BlogContent";
import NewPost from "../../components/Blogs/NewPost/NewPost";
import { UiContext } from "../../context/uiContext";

const { Content, Footer } = Layout;

const Blog: React.FC = () => {
  const { selectPost } = useContext(UiContext);
  const [isNewPost, setIsNewPost] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const PostBtnHandler = (canPost: boolean) => {
    setIsNewPost(canPost);
    selectPost({
      title: "",
      tags: "",
      content: "",
      bannerUrl: "",
      readTime: "",
    });
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <DashboardLayout>
        <Content
          style={{ margin: "0px", background: "#FCFCFC", padding: "50px 24px" }}
        >
          {isNewPost ? (
            <NewPost
              handlePostBlog={PostBtnHandler}
              setIsNewPost={setIsNewPost}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
            />
          ) : (
            <BlogContent
              handlePostBlog={PostBtnHandler}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
            />
          )}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          All rights reserved ©{new Date().getFullYear()} Created by Tranzitech
        </Footer>
      </DashboardLayout>
    </Layout>
  );
};

export default Blog;
