import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { requestInterceptor } from "./requestInterceptor";
import { responseErr, responseInterceptor } from "./responseInterceptor";

export const Instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_BASEURL}/api`
      : //@ts-ignore
        `${window.env?.apiURL}/api`,
  headers: {
    Accept: "application/json",
  },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
console.log("window: ", window.env);

// export const Instance = axios.create({
//   baseURL: `${process.env.REACT_APP_API_BASEURL}/api`,
// });

Instance.interceptors.request.use(
  (config: any) => requestInterceptor(config),
  (error: any) => Promise.reject(error)
);

Instance.interceptors.response.use(
  (res) => responseInterceptor(res),
  async (err) => responseErr(err, Instance, `$`)
);
