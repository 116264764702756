import { createContext, useState, useCallback } from "react";
import { Status } from "../utils/enums/status.enum";

interface IPost {
  id?: number;
  bannerUrl: string;
  title: string;
  tags: string;
  content: string;
  date?: string;
  readTime: string;
  status?: Status;
  //   children?: DataType[];
}

interface IContext {
  posts: IPost[];
  post: IPost;
  selectPost: (item: IPost) => void;
  getPosts: (items: any) => void;
  login: Function;
  logout: Function;
  userCred: any;
  loggedIn: Boolean;
  showDrawer: boolean;
  handleShowDrawer: (canShow: boolean) => void;
  isEdit: boolean;
  handleNewPost: (canPost: boolean) => void;
  subscribers: any[];
  getSubscribers: (items: any[]) => void;
}

const defaultValue: IContext = {
  posts: [],
  post: {
    id: 0,
    title: "",
    bannerUrl: "",
    tags: "",
    date: "",
    content: "",
    status: 1 | 2,
    readTime: "",
  },
  selectPost: (item: IPost) => {},
  getPosts: (items: Array<IPost>) => {},
  login: (cred: any) => {},
  logout: () => {},
  userCred: null,
  loggedIn: false,
  showDrawer: false,
  handleShowDrawer: (show: boolean) => {},
  isEdit: false,
  handleNewPost: (canPost: boolean) => {},
  getSubscribers: (item: any[]) => {},
  subscribers: [],
};

export const UiContext = createContext<IContext>(defaultValue);

export const UiProvider = ({ children }: any) => {
  const newPost = {
    id: 0,
    title: "",
    banner: "",
    tags: "",
    date: "",
    content: "",
  };
  const [posts, setPosts] = useState<any>([]);
  const [post, setPost] = useState<any>(newPost);
  const [userCred, setUserCred] = useState<any>(null);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [subscribers, setSubscribers] = useState<any[]>([]);

  const selectPost = (item: IPost) => {
    setPost(item);
  };

  const getPosts = useCallback(
    (items: IPost[]) => {
      setPosts(items);
    },
    [posts]
  );

  const handleShowDrawer = (canShow: boolean) => {
    setShowDrawer(canShow);
  };

  const handleNewPost = (isNew: boolean) => {
    setIsEdit(isNew);
  };

  const getSubscribers = (items: any[]) => {
    setSubscribers(items);
  };

  const login = (cred: any) => {
    setUserCred(cred);
    setLoggedIn(true);
  };

  const logout = () => {
    setUserCred(null);
    setLoggedIn(false);
  };
  return (
    <UiContext.Provider
      value={{
        posts,
        post,
        selectPost,
        getPosts,
        login,
        logout,
        userCred,
        loggedIn,
        showDrawer,
        handleShowDrawer,
        handleNewPost,
        isEdit,
        subscribers,
        getSubscribers,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};
