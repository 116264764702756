import {
  useEffect,
  useState,
  useContext,
  useCallback,
  ReactElement,
} from "react";

// import { LoadingOutlined } from "@ant-design/icons";
// import { Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";

import Statistics from "../Statistics/Statistics";
import TableComponent from "../../Table/Table";
import Button from "../../UiKits/Button";
import { data } from "../../../data/posts";
import { UiContext } from "../../../context/uiContext";
import { getAllPosts } from "../../../utils/services/post";

import * as Icons from "../../../utils/icons/index";
import { Status } from "../../../utils/enums/status.enum";

interface IBlogContentProps {
  handlePostBlog: (canPost: boolean) => void;
  isEdit: boolean;
  setIsEdit: (canEdit: boolean) => void;
}

interface IStatistics {
  id: number;
  icon: () => ReactElement;
  title: string;
  total: number;
}

const tableHeader: any = [
  {
    title: "Banner",
    dataIndex: "banner",
    key: "banner",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: "12%",
  },
  {
    title: "Tag",
    dataIndex: "tag",
    width: "30%",
    key: "tag",
  },
  {
    title: "Date",
    dataIndex: "date",
    width: "30%",
    key: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "30%",
    key: "status",
  },
];

const BlogContent: React.FC<IBlogContentProps> = ({
  handlePostBlog,
  setIsEdit,
  isEdit,
}) => {
  const { getPosts, posts } = useContext(UiContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [allPosts, setAllPosts] = useState<any[]>(posts || []);

  const { BlogsIcon, PublishedIcon, DraftIcon } = Icons;

  const publishedPosts = posts.filter(
    (item) => item.status === Status.PUBLISHED
  );
  const draftPosts = posts.filter((item) => item.status === Status.DRAFT);

  const statistics: IStatistics[] = [
    {
      id: 1,
      title: "Total Posts",
      icon: BlogsIcon,
      total: posts.length,
    },
    {
      id: 2,
      title: "Total Published",
      icon: PublishedIcon,
      total: publishedPosts.length,
    },
    {
      id: 3,
      title: "Total Drafts",
      icon: DraftIcon,
      total: draftPosts.length,
    },
  ];

  const fetchPost = useCallback(async (search: string) => {
    setIsLoading(true)
    const payload = {
      pageSize: 30,
      pageNumber: 1,
      parameter: {
        query: search,
      },
    };
    try {
      const postData: any = await getAllPosts(payload);
      getPosts(postData.data.data.object);
      setAllPosts(postData.data.data.object);
    } catch (error) {
      console.log("Error ", error);
      (() =>
        toast.error("There was an error while trying to fetch posts !", {
          position: "top-center",
        }))();
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchPost(searchText);
  }, [searchText]);

  // const filterData = allPosts.filter((item) =>
  //   item.title.toLowerCase().includes(searchText.toLowerCase())
  // );

  const filterData = () => {
    if (searchText) {
      return allPosts.filter((item) =>
        item?.title?.toLowerCase().includes(searchText.toLowerCase())
      );
    } else {
      return allPosts;
    }
  };

  const handleNewPost = () => {
    handlePostBlog(true);
    setIsEdit(false);
  };

  return (
    <div>
      <div
        style={{
          margin: 0,
          // height: 48,
          background: "#FCFCFC",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "50px 0",
          // backgroundColor: "red",
        }}
      >
        <div
          style={{
            width: "30%",
          }}
        >
          <h1
            style={{
              fontSize: 24,
              color: "#06001b",
              fontWeight: 600,
              fontFamily: "Roboto",
            }}
          >
            Blogs
          </h1>
          <p
            style={{
              fontSize: 12,
              color: "#06001b99",
              fontWeight: 400,
              fontFamily: "Roboto",
            }}
          >
            An exciting day to be creative once more
          </p>
        </div>
        <div
          style={{
            width: "20%",
            fontSize: 16,
          }}
        >
          <Button
            label="Post a Blog"
            btnClass="bg-[#120051] text-[16px] text-white"
            handleClick={handleNewPost}
          />
        </div>
      </div>
      <Statistics statistics={statistics} />
      <TableComponent
        data={filterData()}
        setSearchText={setSearchText}
        searchText={searchText}
        handlePostBlog={handlePostBlog}
        setIsEdit={setIsEdit}
        tableHeader={tableHeader}
        tableTitle="Recent Blogs"
        blogComp={true}
        isLoading={isLoading}
      />
      <ToastContainer />
    </div>
  );
};

export default BlogContent;
