import { BrowserRouter, Route, Routes } from "react-router-dom";

// import Login from './pages/Login/Login';
import Login from "./pages/Login/Login";
import Blog from "./pages/Blog/Blog";
import Subscribers
  from "./pages/Subscribers/Subscribers";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { UiProvider } from "./context/uiContext";

import "./App.css";

function App() {
  return (
    <UiProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route path="/blogs" element={<Blog />} /> */}
          <Route
            path="/blogs"
            element={<ProtectedRoute component={<Blog />} />}
          />
          <Route path="/subscribers" element={<ProtectedRoute component={<Subscribers />} />} />
        </Routes>
      </BrowserRouter>
    </UiProvider>
  );
}

export default App;
