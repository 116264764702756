import React, { useState } from "react";
import { Button, Drawer } from "antd";

interface IDrawerComponentProps {
  children: React.ReactNode;
  showPreviewModal: boolean;
  setPreviewPost: (canClose: boolean) => void;
}

const DrawerComponent: React.FC<IDrawerComponentProps> = ({
  children,
  showPreviewModal,
  setPreviewPost,
}) => {
  const [open, setOpen] = useState(showPreviewModal);

  const onClose = () => {
    setPreviewPost(false);
  };

  return (
    <>
      <Drawer width={600} closable={false} onClose={onClose} open={open}>
        {children}
      </Drawer>
    </>
  );
};

export default DrawerComponent;
