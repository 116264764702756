export enum Status {
  PUBLISHED = 2,
  DRAFT = 1,
  DELETE = 3,
}

export enum BlogStatus {
  ACTIVE = "PUBLISHED",
  INACTIVE = "DRAFT",
  DELETE = "DELETE",
}
