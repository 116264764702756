import React, { useEffect } from "react";

import { Layout, theme } from "antd";
import { getSavedState } from "../../utils/services/localStorage.service";
import { ADMIN_USER } from "../../utils/constant/userPrivileges";

const { Header } = Layout;

type DashboardLayoutProps = {
  children: React.ReactNode;
};

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const loggedInUser: any = localStorage.getItem(ADMIN_USER);
  const userName = JSON.parse(loggedInUser).data.data.fullName;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  function createAcronym(phrase: string) {
    // Split the input phrase into words
    const words = phrase.split(" ");

    // Map each word to its first letter and capitalize it
    const acronym = words.map((word) => word[0].toUpperCase()).join("");

    return acronym;
  }

  return (
    <Layout>
      <Header style={{ padding: 0, background: "#FCFCFC" }}>
        <div className="flex justify-end pr-[20px]  text-[14px] font-bold pb-[12px]">
          <h1>{userName}</h1>
          <div className="bg-[#06001b0d] h-[40px] w-[40px] overview-hidden rounded-[50%] mt-[10px] ml-[10px]">
            <h1 className="h-[45px] w-[45px] mt-[-10px] ml-[10px]">
              {createAcronym(userName)}
            </h1>
          </div>
        </div>
      </Header>
      {children}
    </Layout>
  );
};

export default DashboardLayout;
