import React, { useState } from "react";
import { Button, Modal } from "antd";

interface IModalComponentProps {
  children: React.ReactNode;
  canPost: boolean;
  setCanPost: (isPosted: boolean) => void;
  classes?: string;
}

const ModalComponent: React.FC<IModalComponentProps> = ({
  children,
  canPost,
  setCanPost,
  classes,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        // title="Basic Modal"
        open={canPost}
        onOk={handleOk}
        onCancel={() => setCanPost(false)}
        footer={null}
        className={`${classes}`}
      >
        {children}
      </Modal>
    </div>
  );
};

export default ModalComponent;
