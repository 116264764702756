import { useState, FC, ChangeEvent, FormEvent } from "react";
import axios from "axios";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import { Input } from "../UiKits/Input";
import Button from "../UiKits/Button";

type UserInputType = {
  userName: string;
  password: string;
  rememberMe: boolean;
};

interface IForm {
  authenticateUser: (data: any) => void;
  isLoading: boolean;
}

const Form: FC<IForm> = ({ authenticateUser, isLoading }) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<UserInputType>({
    userName: "",
    password: "",
    rememberMe: false,
  });
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
    setUserInput({ ...userInput, rememberMe: e.target.checked });
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserInput((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const onSubmitHandler = async (e: FormEvent) => {
    e.preventDefault();
    if (!userInput.userName || !userInput.password) {
      setIsValid(false);
      return;
    } else {
      setIsValid(true);
      const payload = {
        Username: userInput.userName,
        Password: userInput.password,
        RememberMe: userInput.rememberMe,
      };
      authenticateUser(payload);
      setIsSuccessful(true);
    }
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <label htmlFor="email" className="mb-[6px]">
        Email
      </label>
      <Input
        placeholder="Email"
        type="text"
        value={userInput.userName}
        name="userName"
        handleChange={onChangeHandler}
        styles={"mb-[20px] bg-transparent"}
      />
      <label htmlFor="password" className="mb-[6px]">
        Password
      </label>
      <Input
        placeholder="Password"
        value={userInput.password}
        name="password"
        handleChange={onChangeHandler}
        type="password"
        styles={"mb-[20px] bg-transparent"}
      />
      <div className="mb-[31px] flex flex-row justify-between">
        <Checkbox
          onChange={onChange}
          //   style={{ borderColor: '#120051', backgroundColor: '#120051' }}
          //   className='bg-[#120051]'
        >
          Remember me
        </Checkbox>
        <a href="/forgot-password" className="text-sm text-[#120051]">
          Forgot password
        </a>
      </div>

      <Button
        label="Login"
        btnClass="bg-[#120051] text-white"
        isLoading={isLoading}
      />
    </form>
  );
};

export default Form;
