import { ADMIN_USER } from "../constant/userPrivileges";

export function getSavedState(key: string) {
  let savedState = {};

  try {
    let savedItem = localStorage.getItem(key);

    if (typeof savedItem === "string") {
      return JSON.parse(savedItem);
    }


    return savedState;
  } catch (error) {
    return savedState;
  }
}

export function getUserPermission(): Set<string> {
  const userInfo = getSavedState(ADMIN_USER);
  if (userInfo && userInfo.permission) {
    return new Set(userInfo.permission);
  }

  return new Set([]);
}

export function saveLocalState(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function deleteLocalState(key: string) {
  localStorage.removeItem(key);
}
