// import React, { useState } from "react";
// import { InboxOutlined } from "@ant-design/icons";
// import { Upload, message, Button } from "antd";
// import type { UploadProps } from "antd";

// const { Dragger } = Upload;

// const MAX_IMAGE_WIDTH = 800; // Maximum image width in pixels
// const MAX_IMAGE_HEIGHT = 400; // Maximum image height in pixels

// interface IUploadInputProps {
//   handleChange: (img: string) => void;
//   postImg: string;
// }

// const UploadInput: React.FC<IUploadInputProps> = ({
//   handleChange,
//   postImg,
// }) => {
//   const [previewImage, setPreviewImage] = useState<string | null>(postImg);
//   const customRequest = async ({ file, onSuccess, onError }: any) => {
//     try {
//       const base64Data = await getBase64Data(file);
//       handleChange(base64Data);
//       setPreviewImage(base64Data);

//       // You can perform additional processing here

//       onSuccess(file);
//     } catch (error) {
//       console.error("Error reading file:", error);
//       onError(error);
//     }
//   };

//   const getBase64Data = (file: any) => {
//     return new Promise<string>((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onload = (e) => {
//         if (e.target) {
//           resolve(e.target.result as string);
//         } else {
//           reject(new Error("Failed to read the file."));
//         }
//       };
//       reader.onerror = (e) => {
//         reject(e);
//       };
//       reader.readAsDataURL(file);
//     });
//   };

//   const beforeUpload = (file: any) => {
//     return new Promise<boolean>((resolve, reject) => {
//       const img = new Image();
//       img.src = URL.createObjectURL(file);

//       img.onload = () => {
//         if (img.width >= MAX_IMAGE_WIDTH && img.height >= MAX_IMAGE_HEIGHT) {
//           console.log("Width, Height ", img.width, img.height);
//           message.error(
//             `Image dimensions exceed ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT} pixels.`
//           );
//           resolve(false);
//         } else {
//           resolve(true); // Resolve true for acceptable dimensions
//         }
//       };

//       img.onerror = (error) => {
//         console.error("Error loading image:", error);
//         reject(error);
//       };
//     });
//   };

//   const clearPreview = () => {
//     setPreviewImage(null); // Clear the preview image URL
//   };

//   const props: UploadProps = {
//     name: "file",
//     customRequest,
//     multiple: false,
//     accept: ".jpg,.jpeg,.png",
//     // onPreview,
//     beforeUpload,

//     onChange(info) {
//       const { status } = info.file;
//       console.log("Status ", status);
//       if (status !== "uploading") {
//         console.log(info.file, info.fileList);
//       }
//       if (status === "done") {
//         message.success(`${info.file.name} file uploaded successfully.`);
//       } else if (status === "error") {
//         message.error(`${info.file.name} file upload failed.`);
//       }
//     },
//     onDrop(e) {
//       console.log("Dropped files", e.dataTransfer.files);
//     },
//   };

//   return (
//     <div>
//       {previewImage ? (
//         <div className="bg-[#06001b05] rounded-[12px] p-[7px]">
//           <div
//             className="flex justify-center items-center"
//             style={{
//               width: "100%",
//               height: "106px",
//               overflow: "hidden",
//             }}
//           >
//             <img src={previewImage} alt="Preview" style={{ height: "325px" }} />
//           </div>
//           <div className="flex justify-center items-center mt-[20px]">
//             <Button
//               onClick={clearPreview}
//               style={{ background: "red", color: "#fff" }}
//             >
//               Clear Preview
//             </Button>
//           </div>
//         </div>
//       ) : (
//         <Dragger
//           {...props}
//           style={{ borderColor: "transparent", marginBottom: "30px" }}
//         >
//           <p
//             className="ant-upload-text bg-[#fff] w-[172px] mb-[12px] text-center p-[10px] rounded-[10px]"
//             style={{ margin: "auto" }}
//           >
//             <h2>Upload Image</h2>
//           </p>
//           <p className="ant-upload-hint">Click to upload or drag and drop</p>
//           <small className="ant-upload-hint">Jpeg, Png (max. 800x400px)</small>
//         </Dragger>
//       )}
//     </div>
//   );
// };

// export default UploadInput;

import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Upload, message, Button } from "antd";
import type { UploadProps } from "antd";

const { Dragger } = Upload;

const MAX_IMAGE_WIDTH = 800; // Maximum image width in pixels
const MAX_IMAGE_HEIGHT = 400; // Maximum image height in pixels

interface IUploadInputProps {
  handleChange: (img: File) => void; // Changed to accept a File object
  postImg: string;
}

const UploadInput: React.FC<IUploadInputProps> = ({
  handleChange,
  postImg,
}) => {
  const [previewImage, setPreviewImage] = useState<string | null>(postImg);

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    try {
      handleChange(file); // Pass the File object to the handleChange function
      setPreviewImage(URL.createObjectURL(file)); // Set the preview image URL
      // You can perform additional processing here

      onSuccess(file);
    } catch (error) {
      onError(error);
    }
  };

  const beforeUpload = (file: any) => {
    return new Promise<boolean>((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width >= MAX_IMAGE_WIDTH && img.height >= MAX_IMAGE_HEIGHT) {
          message.error(
            `Image dimensions exceed ${MAX_IMAGE_WIDTH}x${MAX_IMAGE_HEIGHT} pixels.`
          );
          resolve(false);
        } else {
          resolve(true); // Resolve true for acceptable dimensions
        }
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const clearPreview = () => {
    setPreviewImage(null); // Clear the preview image URL
  };

  const props: UploadProps = {
    name: "file",
    customRequest,
    multiple: false,
    accept: ".jpg,.jpeg,.png",
    beforeUpload,

    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div>
      {previewImage ? (
        <div className="bg-[#06001b05] rounded-[12px] p-[7px]">
          <div
            className="flex justify-center items-center"
            style={{
              width: "100%",
              height: "106px",
              overflow: "hidden",
            }}
          >
            <img src={previewImage} alt="Preview" style={{ height: "325px" }} />
          </div>
          <div className="flex justify-center items-center mt-[20px]">
            <Button
              onClick={clearPreview}
              style={{ background: "red", color: "#fff" }}
            >
              Clear Preview
            </Button>
          </div>
        </div>
      ) : (
        <Dragger
          {...props}
          style={{ borderColor: "transparent", marginBottom: "30px" }}
        >
          <div
            className="ant-upload-text bg-[#fff] w-[172px] mb-[12px] text-center p-[10px] rounded-[10px]"
            style={{ margin: "auto" }}
          >
            <h2>Upload Image</h2>
          </div>
          <p className="ant-upload-hint">Click to upload or drag and drop</p>
          <small className="ant-upload-hint">Jpeg, Png (max. 800x400px)</small>
        </Dragger>
      )}
    </div>
  );
};

export default UploadInput;
