import React from 'react'

const BlogIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3333 18.9583H6.66667C3.625 18.9583 1.875 17.2083 1.875 14.1666V5.83329C1.875 2.79163 3.625 1.04163 6.66667 1.04163H13.3333C16.375 1.04163 18.125 2.79163 18.125 5.83329V14.1666C18.125 17.2083 16.375 18.9583 13.3333 18.9583ZM6.66667 2.29163C4.28333 2.29163 3.125 3.44996 3.125 5.83329V14.1666C3.125 16.55 4.28333 17.7083 6.66667 17.7083H13.3333C15.7167 17.7083 16.875 16.55 16.875 14.1666V5.83329C16.875 3.44996 15.7167 2.29163 13.3333 2.29163H6.66667Z'
        fill='#120051'
      />
      <path
        d='M15.4163 7.70833H13.7497C12.483 7.70833 11.458 6.68333 11.458 5.41667V3.75C11.458 3.40833 11.7413 3.125 12.083 3.125C12.4247 3.125 12.708 3.40833 12.708 3.75V5.41667C12.708 5.99167 13.1747 6.45833 13.7497 6.45833H15.4163C15.758 6.45833 16.0413 6.74167 16.0413 7.08333C16.0413 7.425 15.758 7.70833 15.4163 7.70833Z'
        fill='#120051'
      />
      <path
        d='M10.0003 11.4584H6.66699C6.32533 11.4584 6.04199 11.175 6.04199 10.8334C6.04199 10.4917 6.32533 10.2084 6.66699 10.2084H10.0003C10.342 10.2084 10.6253 10.4917 10.6253 10.8334C10.6253 11.175 10.342 11.4584 10.0003 11.4584Z'
        fill='#120051'
      />
      <path
        d='M13.3337 14.7916H6.66699C6.32533 14.7916 6.04199 14.5083 6.04199 14.1666C6.04199 13.825 6.32533 13.5416 6.66699 13.5416H13.3337C13.6753 13.5416 13.9587 13.825 13.9587 14.1666C13.9587 14.5083 13.6753 14.7916 13.3337 14.7916Z'
        fill='#120051'
      />
    </svg>
  );
}

export default BlogIcon