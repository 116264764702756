import React, { CSSProperties } from "react";
import { Spinner } from "./Spinner";

type ButtonProps = {
  handleClick?: (e: any) => void;
  label: string;
  size?: "Large" | "Medium" | "Small";
  type?: any;
  isLoading?: boolean;
  disabled?: boolean;
  className?: CSSProperties;
  btnClass?: string;
};

const Button: React.FC<ButtonProps> = ({
  label,
  className,
  isLoading,
  btnClass,
  handleClick,
}) => {
  return (
    <button
      onClick={handleClick}
      className={`${btnClass} w-full p-4 font-Roboto flex justify-center items-center text-lg h-[48px] rounded-[12px] ${
        className && className
      } `}
      disabled={isLoading}
    >
      {isLoading ? <Spinner /> : label}
    </button>
  );
};

export default Button;
