import React from "react";

const DraftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M10.6667 15.6667H5.33333C2.9 15.6667 1.5 14.2667 1.5 11.8334V5.16671C1.5 2.73337 2.9 1.33337 5.33333 1.33337H10.6667C13.1 1.33337 14.5 2.73337 14.5 5.16671V11.8334C14.5 14.2667 13.1 15.6667 10.6667 15.6667ZM5.33333 2.33337C3.42667 2.33337 2.5 3.26004 2.5 5.16671V11.8334C2.5 13.74 3.42667 14.6667 5.33333 14.6667H10.6667C12.5733 14.6667 13.5 13.74 13.5 11.8334V5.16671C13.5 3.26004 12.5733 2.33337 10.6667 2.33337H5.33333Z"
        fill="#120051"
      />
      <path
        d="M6.00033 7.9067C5.88699 7.9067 5.77366 7.88671 5.66699 7.84005C5.36033 7.70671 5.16699 7.4067 5.16699 7.08004V1.83337C5.16699 1.56004 5.39366 1.33337 5.66699 1.33337H10.3337C10.607 1.33337 10.8337 1.56004 10.8337 1.83337V7.07336C10.8337 7.4067 10.6403 7.70671 10.3337 7.83337C10.0337 7.96671 9.68034 7.90672 9.43368 7.68005L8.00033 6.3667L6.56698 7.68673C6.40698 7.83339 6.20699 7.9067 6.00033 7.9067ZM8.00033 5.30672C8.20033 5.30672 8.40698 5.38003 8.56698 5.52669L9.83366 6.69336V2.33337H6.16699V6.69336L7.43368 5.52669C7.59368 5.38003 7.80033 5.30672 8.00033 5.30672Z"
        fill="#120051"
      />
      <path
        d="M11.6663 10.3334H8.83301C8.55967 10.3334 8.33301 10.1067 8.33301 9.83337C8.33301 9.56004 8.55967 9.33337 8.83301 9.33337H11.6663C11.9397 9.33337 12.1663 9.56004 12.1663 9.83337C12.1663 10.1067 11.9397 10.3334 11.6663 10.3334Z"
        fill="#120051"
      />
      <path
        d="M11.6667 13H6C5.72667 13 5.5 12.7733 5.5 12.5C5.5 12.2267 5.72667 12 6 12H11.6667C11.94 12 12.1667 12.2267 12.1667 12.5C12.1667 12.7733 11.94 13 11.6667 13Z"
        fill="#120051"
      />
    </svg>
  );
};

export default DraftIcon;
