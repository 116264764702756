import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import { Status } from "../../utils/enums/status.enum";
import { newBlogPost, updateBlogPost } from "../../utils/services/post";

import Button from "../UiKits/Button";
import ModalComponent from "../UiKits/Modal";

interface IPost {
  id?: number;
  title: string;
  bannerUrl: any;
  tags: string;
  readTime: string;
  content: string;
}

interface IPostPreviewProps {
  setPreviewPost: (canShow: boolean) => void;
  setIsNewPost: (isNewPost: boolean) => void;
  newPost: IPost;
  isEdit: boolean;
}

const PostPreview: React.FC<IPostPreviewProps> = ({
  setPreviewPost,
  newPost,
  setIsNewPost,
  isEdit,
}) => {
  const [canPost, setCanPost] = useState(false);

  const imageUrl =
    typeof newPost.bannerUrl === "string"
      ? newPost.bannerUrl
      : URL.createObjectURL(newPost.bannerUrl);

  const today = new Date().toISOString().split("T")[0];

  const handlePublish = () => {
    const postStatus = Status.PUBLISHED;
    const formData = new FormData();

    formData.append("Title", newPost.title);
    formData.append("Tags", newPost.tags);
    formData.append("Content", newPost.content);
    formData.append("Banner", newPost.bannerUrl);
    formData.append("Status", `${postStatus}`);
    formData.append("ReadTime", `${+newPost.readTime}`);
    postNewBlog(formData);
  };

  const handleUpdatePost = () => {
    const postStatus = Status.PUBLISHED;
    const id = newPost.id && newPost.id.toString();
    const formData = new FormData();

    formData.append("Id", `${id}`);
    formData.append("Title", newPost.title);
    formData.append("Tags", newPost.tags);
    formData.append("Content", newPost.content);
    formData.append("Banner", newPost.bannerUrl);
    formData.append("Status", `${postStatus}`);
    formData.append("ReadTime", `${+newPost.readTime}`);
    updateNewBlog(formData);
  };

  const goToPosts = () => {
    window.location.href = "/blogs";
    setPreviewPost(false);
    setCanPost(false);
    setIsNewPost(false);
  };

  const { mutateAsync: postNewBlog, isLoading } = useMutation(newBlogPost, {
    onSuccess: async (data: any) => {
      setCanPost(true);
      toast.success(`${data.data.description}`, {
        position: "top-center",
      });
    },
    onError: (error: any) => {
      console.log("Error ", error);
      toast.error(
        "There was error while trying to publish your post, please try again.",
        {
          position: "top-center",
        }
      );
    },
  });

  const { mutateAsync: updateNewBlog, isLoading: updatePostLoading } =
    useMutation(updateBlogPost, {
      onSuccess: async (data: any) => {
        setCanPost(true);
      },
      onError: (error: any) => {
        console.log("Error ", error);
        toast.error(
          "There was a error while trying to update your post. please try again.",
          {
            position: "top-center",
          }
        );
      },
    });
  return (
    <div>
      <div className="flex row">
        <div onClick={() => setPreviewPost(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.56945 18.82C9.37945 18.82 9.18945 18.75 9.03945 18.6L2.96945 12.53C2.67945 12.24 2.67945 11.76 2.96945 11.47L9.03945 5.4C9.32945 5.11 9.80945 5.11 10.0995 5.4C10.3895 5.69 10.3895 6.17 10.0995 6.46L4.55945 12L10.0995 17.54C10.3895 17.83 10.3895 18.31 10.0995 18.6C9.95945 18.75 9.75945 18.82 9.56945 18.82Z"
              fill="#292D32"
            />
            <path
              d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
              fill="#292D32"
            />
          </svg>
        </div>
        <div className="ml-[20px] w-[277px]">
          <h1 className="text-[24px] font-bold mt-[-7px]">Preview</h1>
          <small>
            Kindly go through the layout and structure of your blog before
            publishing
          </small>
        </div>
      </div>
      <div className="p-[30px] border border-[#06001b33] rounded-[20px] mt-[40px] mb-[70px]">
        <h1 className="text-[#06001bcc] text-[24px] font-bold leading-125">
          {newPost.title}
        </h1>
        <div className="flex row">
          <p className="mr-[7px] text-[10px] text-[#120051]">
            {moment(today).format("LL")}
          </p>
          <div className="mt-[4.9px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5"
              height="5"
              viewBox="0 0 4 4"
              fill="none"
            >
              <circle cx="1.60204" cy="2.25963" r="1.52782" fill="#120051" />
            </svg>
          </div>
          <p className="ml-[7px] text-[10px] text-[#120051]">
            {newPost.readTime} mins read
          </p>
        </div>
        <div className="border-t border-solid border-#06001b33 mt-[14.26px] py-[15px]">
          <img src={imageUrl} alt={newPost.title} />
        </div>
        <div
          className="text-[#06001bcc] text-[12px] leading-150"
          dangerouslySetInnerHTML={{ __html: newPost.content }}
        />
      </div>
      <div className="flex justify-end">
        <Button
          label={isEdit ? "Update Blog" : "Publish"}
          btnClass="bg-[#120051] text-white w-[200px]"
          handleClick={isEdit ? handleUpdatePost : handlePublish}
          isLoading={isEdit ? updatePostLoading : isLoading}
        />
      </div>
      {canPost ? (
        <ModalComponent
          canPost={canPost}
          setCanPost={setCanPost}
          classes="w-[441px] py-[57px]"
        >
          <div className="text-center m-auto bg-['red]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="160"
              height="160"
              viewBox="0 0 160 160"
              fill="none"
              style={{ margin: "auto" }}
            >
              <circle
                cx="80"
                cy="80"
                r="80"
                fill="#06001B"
                fillOpacity="0.05"
              />
              <path
                d="M53 79.1538L72.25 98L108 63"
                stroke="#120051"
                strokeWidth="8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <h1 className="text-center text-[#06001B] text-[24px] font-bold mt-[20px] mb-[10px]">
            Blog Successfully {isEdit ? "Updated" : "Published"}
          </h1>
          <p className="text-center text-[#06001b99] text-[14px] leading-150 font-medium w-[301px] m-auto mb-[40px]">
            You have successfully {isEdit ? "updated" : "published"} your blog
            and your website will be updated accordingly
          </p>
          <Button
            label="Done"
            btnClass="bg-[#120051] text-white w-[200px] m-auto"
            handleClick={goToPosts}
          />
        </ModalComponent>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default PostPreview;
