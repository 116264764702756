import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { ADMIN_USER } from "../constant/userPrivileges";
import { deleteLocalState, saveLocalState } from "../services/localStorage.service";
import { Instance } from "./instance";

export const responseInterceptor = (response: AxiosResponse<any, any>) => {
  return response;
};

export const responseErr = (
  err: AxiosError,
  instance: AxiosInstance,
  url: string
) => {
  const originalConfig = err.config;
  if (err.response) {
    console.log("response err", err);

    // Access Token was expired
    // @ts-ignore
    if (err.response.status === 401 && !originalConfig._retry) {
      deleteLocalState(ADMIN_USER);
      window.location.href = "/";
    }
    if (err.response.status === 403 && err.response.data) {
      return Promise.reject(err.response.data);
    }
    return Promise.reject(err);
  }

  // return  err
  return Promise.reject(err);
};
