

import { Instance } from "../axios/instance";

export const getAllPosts = async (payload: any) => {
  const data = await Instance.post("/Blog/GetAllBlogs", payload);
  return data;
};

export const newBlogPost = async (payload: any) => {
  const data = await Instance.post("/Blog/PostBlog", payload);
  return data;
};

export const updateBlogPost = async (payload: any) => {
  const data = await Instance.put("/Blog/UpdateBlog", payload);
  return data;
};

export const deleteBlogPost = async (id: string) => {
  const data = await Instance.post("/Blog/DeleteBlog", id);
  return data;
};
