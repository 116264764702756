import React, { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";

import { useMutation } from "react-query";
import { UiContext } from "../../../context/uiContext";
import { Status } from "../../../utils/enums/status.enum";
import {
  deleteBlogPost,
  newBlogPost,
  updateBlogPost,
} from "../../../utils/services/post";
import DrawerComponent from "../../Drawer/Drawer";
import PostPreview from "../../PostPreview/PostPreview";
import TextArea from "../../TextArea/TextArea";
import Button from "../../UiKits/Button";
import { Input } from "../../UiKits/Input";
import ModalComponent from "../../UiKits/Modal";
import UploadInput from "../../UiKits/Upload";
import { NavLink } from "react-router-dom";

interface INewPostPros {
  handlePostBlog: (canPost: boolean) => void;
  setIsNewPost: (isNewPost: boolean) => void;
  isEdit: boolean;
  setIsEdit: (canEdit: boolean) => void;
}

const NewPost: React.FC<INewPostPros> = ({
  handlePostBlog,
  setIsNewPost,
  isEdit,
  setIsEdit,
}) => {
  const { post } = useContext(UiContext);
  const [previewPost, setPreviewPost] = useState<boolean>(false);
  const [validForm, setValidForm] = useState<boolean>(true);
  const [newPost, setNewPost] = useState(post);
  const [isDraft, setIsDraft] = useState<boolean>(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);

  const handleNext = (e: any) => {
    e.preventDefault();
    if (isNaN(+newPost.readTime)) {
      setNewPost({ ...newPost, readTime: "" });
      setValidForm(false);
      return;
    }
    if (
      !newPost.title ||
      !newPost.tags ||
      !newPost.bannerUrl ||
      !newPost.content ||
      !newPost.readTime
    ) {
      setValidForm(false);
      return;
    }
    setValidForm(true);
    setPreviewPost(true);
  };

  const handleDraft = (e: any) => {
    e.preventDefault();
    if (isNaN(+newPost.readTime)) {
      setNewPost({ ...newPost, readTime: "" });
      setValidForm(false);
      return;
    }
    const id = newPost.id && newPost.id.toString();
    const postStatus = Status.DRAFT;

    console.log("newPost ", newPost);

    const formData = new FormData();
    id && formData.append("Id", `${id}`);
    formData.append("Title", newPost.title);
    formData.append("Tags", newPost.tags);
    formData.append("Content", newPost.content);
    formData.append("Banner", newPost.bannerUrl);
    formData.append("Status", `${postStatus}`);
    formData.append("ReadTime", `${+newPost.readTime}`);

    if (isEdit) {
      updateNewBlog(formData);
      return;
    }
    draftNewBlog(formData);
  };

  const handleCanPostDelete = (e: any) => {
    e.preventDefault();
    setCanDelete(true);
  };

  const handleDeletePost = (e: any, id: string) => {
    e.preventDefault();
    const payload: any = {
      id: id,
    };
    deletePost(payload);
  };

  const { mutateAsync: draftNewBlog, isLoading } = useMutation(newBlogPost, {
    onSuccess: async (data: any) => {
      setIsDraft(true);
      // toast.success(`${data.data.description}`);
      toast.success("Blog successfully saved as draft", {
        position: "top-center",
      });
    },
    onError: (error: any) => {
      console.log("Error ", error);
      toast.error(
        "There was an error while trying to draft your post, please try again.",
        {
          position: "top-center",
        }
      );
    },
  });

  const { mutateAsync: updateNewBlog, isLoading: updatePostLoading } =
    useMutation(updateBlogPost, {
      onSuccess: async (data: any) => {
        setIsDraft(true);
        toast.success(`Blog successfully saved as draft`, {
          position: "top-center",
        });
      },
      onError: (error: any) => {
        console.log("Error ", error);
        toast.error(
          "There was a error while trying to update the post, please try again.",
          {
            position: "top-center",
          }
        );
      },
    });

  const { mutateAsync: deletePost, isLoading: deleteLoading } = useMutation(
    deleteBlogPost,
    {
      onSuccess: async (data: any) => {
        setIsDeleted(true);
        toast.success(`${data.data.description}`, {
          position: "top-center",
        });
      },
      onError: (error: any) => {
        console.log("Error ", error);
        toast.error(
          "There was an error while trying to delete your post, please try again.",
          {
            position: "top-center",
          }
        );
      },
    }
  );

  const goToPosts = () => {
    window.location.href = "/blogs";
    setIsDraft(false);
  };

  return (
    <div className="bg-[#FCFCFC]">
      <div className="flex row w-[165px] justify-around">
        <div onClick={() => handlePostBlog(false)} className="cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9.57043 18.8201C9.38043 18.8201 9.19043 18.7501 9.04043 18.6001L2.97043 12.5301C2.68043 12.2401 2.68043 11.7601 2.97043 11.4701L9.04043 5.40012C9.33043 5.11012 9.81043 5.11012 10.1004 5.40012C10.3904 5.69012 10.3904 6.17012 10.1004 6.46012L4.56043 12.0001L10.1004 17.5401C10.3904 17.8301 10.3904 18.3101 10.1004 18.6001C9.96043 18.7501 9.76043 18.8201 9.57043 18.8201Z"
              fill="#292D32"
            />
            <path
              d="M20.4999 12.75H3.66992C3.25992 12.75 2.91992 12.41 2.91992 12C2.91992 11.59 3.25992 11.25 3.66992 11.25H20.4999C20.9099 11.25 21.2499 11.59 21.2499 12C21.2499 12.41 20.9099 12.75 20.4999 12.75Z"
              fill="#292D32"
            />
          </svg>
        </div>
        <div className="text-[12px] flex row justify-between mt-[3px] font-bold text-[#06001b99]">
          <p
            className="mr-[4px] text-[#120051] text-underline"
            style={{ textDecoration: "underline" }}
          >
            Blog
          </p>
          {"<"}
          <p className="ml-[4px]">Post a Blog</p>
        </div>
      </div>
      <div className="mt-[28px]">
        <h2 className="text-[24px] font-bold text-[#06001B]">Post a Blog</h2>
        <small className="text-[#06001b99] text-[12px]">
          An exciting day to be creative once more
        </small>
      </div>
      <form className="w-[94%] bg-[#FFF] rounded-[32px] py-[40px] pl-[40px] pr-[190px] mt-[40px] relative">
        {isEdit && (
          <div
            className={`
              ${
                newPost.status === Status.PUBLISHED
                  ? "bg-[#17c0280d] text-[#17C028] rounded-[40px] w-[10%] h-[27px] py-[5px] px-[10px] text-center pt-[5px] absolute right-[0px]"
                  : "bg-[#e8b6061a] text-[#E8B606] rounded-[40px] w-[10%] h-[27px] py-[5px] px-[10px] text-center pt-[5px] absolute right-[0px]"
              }
          `}
          >
            <p>{newPost.status === Status.PUBLISHED ? "Published" : "Draft"}</p>
          </div>
        )}
        <div className="mb-[30px]">
          <label
            htmlFor="title"
            className="mb-[6px] text-[#06001b99] font-semibold text-[14px]"
          >
            Title *
          </label>
          <Input
            placeholder="Blog title"
            name="title"
            value={newPost.title}
            handleChange={(e: any) =>
              setNewPost({ ...newPost, title: e.target.value })
            }
            styles="border border-none bg-[#06001b05] mb-[15px]"
          />
          {!newPost.title && !validForm ? (
            <p className="text-[red] mt-[-10px] text-[12px] font-normal">
              Please provide a title
            </p>
          ) : null}
        </div>

        <div className="mb-[30px]">
          <label
            htmlFor="tags"
            className="mb-[6px] text-[#06001b99] font-semibold text-[14px]"
          >
            Tags *
          </label>
          <Input
            placeholder="Add tags"
            name="tags"
            value={newPost.tags}
            handleChange={(e: any) =>
              setNewPost({ ...newPost, tags: e.target.value })
            }
            styles="border border-none bg-[#06001b05] mb-[15px]"
          />
          {!newPost.tags && !validForm ? (
            <p className="text-[red] mt-[-10px] text-[12px] font-normal">
              Please provide at least 1 tag
            </p>
          ) : null}
        </div>

        <div className="mb-[30px]">
          <label
            htmlFor="banner"
            className="mb-[6px] text-[#06001b99] font-semibold text-[14px]"
          >
            Banner *
          </label>
          <UploadInput
            handleChange={(img: any) =>
              setNewPost({ ...newPost, bannerUrl: img })
            }
            postImg={newPost.bannerUrl}
          />
          {!newPost.bannerUrl && !validForm ? (
            <p className="text-[red] mt-[-10px] text-[12px] font-normal">
              Please attach an image
            </p>
          ) : null}
        </div>

        <div className="mb-[30px]">
          <label
            htmlFor="content"
            className="mb-[6px] text-[#06001b99] font-semibold text-[14px]"
          >
            Content *
          </label>
          <TextArea
            handleTextChange={(text: string) =>
              setNewPost({ ...newPost, content: text })
            }
            postContent={newPost.content}
          />
          {!newPost.content && !validForm ? (
            <p className="text-[red] text-[12px] font-normal">
              Please provide a content
            </p>
          ) : null}
        </div>

        <div className="mb-[30px]">
          <label
            htmlFor="title"
            className="mb-[6px] text-[#06001b99] font-semibold text-[14px]"
          >
            Read Time *
          </label>
          <Input
            placeholder="Read Time"
            name="readTime"
            value={newPost.readTime}
            handleChange={(e: any) =>
              setNewPost({ ...newPost, readTime: e.target.value })
            }
            styles="border border-none bg-[#06001b05] mb-[15px]"
          />
          {!newPost.readTime && !validForm && !isNaN(+newPost.readTime) ? (
            <p className="text-[red] mt-[-10px] text-[12px] font-normal">
              Please provide a read time and it must be a number
            </p>
          ) : null}
        </div>

        <div className="flex row justify-end mt-[120px] ">
          <div>
            {isEdit ? (
              <p
                onClick={(e) => handleCanPostDelete(e)}
                className="text-[red] underline w-[140px] pt-[15px] cursor-pointer"
              >
                {newPost.status === Status.PUBLISHED
                  ? "Delete Post"
                  : "Delete Draft"}
              </p>
            ) : (
              ""
            )}
          </div>
          <Button
            label={"Save as Draft"}
            isLoading={isLoading || updatePostLoading}
            btnClass={`text-[#120051] border border-[#120051] w-[140px] mr-[16px]`}
            handleClick={(e) => handleDraft(e)}
          />
          <Button
            label={isEdit ? "Update Blog" : "Next"}
            btnClass="bg-[#120051] w-[140px] text-white"
            handleClick={(e: any) => handleNext(e)}
          />
        </div>
      </form>
      {previewPost ? (
        <DrawerComponent
          showPreviewModal={previewPost}
          setPreviewPost={setPreviewPost}
        >
          <PostPreview
            setPreviewPost={setPreviewPost}
            newPost={newPost}
            isEdit={isEdit}
            setIsNewPost={setIsNewPost}
          />
        </DrawerComponent>
      ) : null}

      {isDraft || canDelete ? (
        <ModalComponent
          canPost={isDraft || canDelete}
          setCanPost={setIsDraft || setCanDelete}
          classes="w-[441px] py-[57px]"
        >
          {isDraft && !canDelete ? (
            <div>
              <div className="text-center m-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="160"
                  height="160"
                  viewBox="0 0 160 160"
                  fill="none"
                  style={{ margin: "auto" }}
                >
                  <circle
                    cx="80"
                    cy="80"
                    r="80"
                    fill="#06001B"
                    fillOpacity="0.05"
                  />
                  <path
                    d="M53 79.1538L72.25 98L108 63"
                    stroke="#120051"
                    strokeWidth="8"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h1 className="text-center text-[#06001B] text-[24px] font-bold mt-[20px] mb-[10px]">
                Blog Successfully Drafted
              </h1>
              <p className="text-center text-[#06001b99] text-[14px] leading-150 font-medium w-[301px] m-auto mb-[40px]">
                You have successfully drafted your blog
              </p>
              <Button
                label="Done"
                btnClass="bg-[#120051] text-white w-[200px] m-auto"
                handleClick={goToPosts}
              />
            </div>
          ) : (
            <div>
              <div className="text-center m-auto bg-['red]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="160"
                  height="160"
                  viewBox="0 0 160 160"
                  fill="none"
                  style={{ margin: "auto" }}
                >
                  <circle cx="80" cy="80" r="80" fill="#FEF0C7" />
                  <path
                    d="M79.998 91.0005C76.923 91.0005 74.373 88.699 74.373 85.9235V52.077C74.373 49.3016 76.923 47 79.998 47C83.073 47 85.623 49.3016 85.623 52.077V85.9235C85.623 88.699 83.073 91.0005 79.998 91.0005Z"
                    fill="#DC6803"
                  />
                  <path
                    d="M80 113C79.025 113 78.05 112.797 77.15 112.458C76.25 112.12 75.425 111.646 74.675 111.037C74 110.36 73.475 109.683 73.1 108.803C72.725 107.991 72.5 107.111 72.5 106.231C72.5 105.351 72.725 104.471 73.1 103.658C73.475 102.846 74 102.101 74.675 101.424C75.425 100.815 76.25 100.341 77.15 100.003C78.95 99.326 81.05 99.326 82.85 100.003C83.75 100.341 84.575 100.815 85.325 101.424C86 102.101 86.525 102.846 86.9 103.658C87.275 104.471 87.5 105.351 87.5 106.231C87.5 107.111 87.275 107.991 86.9 108.803C86.525 109.683 86 110.36 85.325 111.037C84.575 111.646 83.75 112.12 82.85 112.458C81.95 112.797 80.975 113 80 113Z"
                    fill="#DC6803"
                  />
                </svg>
              </div>
              <h1 className="text-center text-[#06001B] text-[24px] font-bold mt-[20px] mb-[10px]">
                Delete Blog
              </h1>
              <p className="text-center text-[#06001b99] text-[14px] leading-150 font-medium w-[301px] m-auto mb-[40px]">
                Are you sure you want to delete this Blog? This blog will be
                deleted from your website as well.
              </p>
              <div className="flex row justify-between">
                <Button
                  label="Cancel"
                  btnClass="border border-[#120051] text-[#120051] w-[160px] m-auto"
                  handleClick={() => setCanDelete(false)}
                />
                <Button
                  label="Yes, Delete"
                  btnClass="bg-[#120051] text-white w-[160px] m-auto"
                  isLoading={deleteLoading}
                  handleClick={(e) =>
                    handleDeletePost(e, newPost.id!.toString())
                  }
                />
              </div>
            </div>
          )}
        </ModalComponent>
      ) : null}

      {isDeleted ? (
        <ModalComponent
          canPost={isDeleted}
          setCanPost={setIsDeleted}
          classes="w-[441px] py-[57px]"
        >
          <div>
            <div className="text-center m-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="160"
                height="160"
                viewBox="0 0 160 160"
                fill="none"
                style={{ margin: "auto" }}
              >
                <circle
                  cx="80"
                  cy="80"
                  r="80"
                  fill="#06001B"
                  fillOpacity="0.05"
                />
                <path
                  d="M53 79.1538L72.25 98L108 63"
                  stroke="#120051"
                  strokeWidth="8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <h1 className="text-center text-[#06001B] text-[24px] font-bold mt-[20px] mb-[10px]">
              Blog Successfully Deleted
            </h1>
            <p className="text-center text-[#06001b99] text-[14px] leading-150 font-medium w-[301px] m-auto mb-[40px]">
              You have successfully deleted this blog from your website
            </p>
            <Button
              label="Done"
              btnClass="bg-[#120051] text-white w-[200px] m-auto"
              handleClick={goToPosts}
            />
          </div>
        </ModalComponent>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default NewPost;
