import React, { useEffect, useState, useContext } from "react";
import { Empty, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { BlogStatus, Status } from "../../utils/enums/status.enum";
import { UiContext } from "../../context/uiContext";

import "./Table.css";
import TableHeader from "../Blogs/TableHeader/TableHeader";

interface DataType {
  id: number;
  bannerUrl: string;
  title: string;
  tags: any;
  dateCreated: string;
  content: string;
  status: Status;
  //   children?: DataType[];
}

interface TableProps {
  data: any[];
  setSearchText: (text: string) => void;
  searchText: string;
  handlePostBlog: (canPost: boolean) => void;
  setIsEdit: (canEdit: boolean) => void;
  tableHeader: any;
  tableTitle: string;
  blogComp?: boolean;
  isLoading: boolean
}

// rowSelection objects indicates the need for row selection
const rowSelection: TableRowSelection<DataType> = {
  onChange: (selectedRowKeys, selectedRows) => {},
  onSelect: (record, selected, selectedRows) => {},
  onSelectAll: (selected, selectedRows, changeRows) => {},
};

const TableComponent: React.FC<TableProps> = ({
  data,
  setSearchText,
  searchText,
  handlePostBlog,
  setIsEdit,
  tableHeader,
  tableTitle,
  blogComp,
  isLoading
}) => {
  const [selectedTable, setSelectedTable] = useState<string>("all");
  
  const [posts, setPosts] = useState<DataType[]>(data);

  const UiCtx = useContext(UiContext);

  useEffect(() => {
    setPosts(data);
  }, [setPosts, data]);

  const handleTableSelect = (tabName: string) => {
    const postStatus: any = [
      {
        key: 2,
        value: "published",
      },
      {
        key: 1,
        value: "draft",
      },
    ];

    const status = postStatus.filter((item: any) => item.value === tabName);

    if (!status[0]) {
      setPosts(data);
      setSelectedTable(tabName);
      return;
    } else {
      setPosts(() => data.filter((item: any) => item.status === status[0].key));

      setSelectedTable(tabName);
    }
  };

  const handleSelectedPost = (item: any) => {
    handlePostBlog(true);
    setIsEdit(true);
    UiCtx.selectPost(item);
  };

  if (!data) {
    return <p>Loading...</p>;
  }

  const tableHeaders = tableHeader.map((item: any) => (
    <th key={item.key} className="table-header">
      {item.title}
    </th>
  ));

  const tableData = posts.map((item) => (
    <tr
      key={item.id}
      onClick={() => handleSelectedPost(item)}
      className="cursor-pointer"
    >
      <td>
        <img
          style={{
            width: "45px",
            height: "42.451px",
          }}
          src={item.bannerUrl}
          alt={item.title}
        />
      </td>
      <td className="text-[12px]">{item.title && item?.title}</td>
      <td className="text-[10px] flex row">
        {item.tags &&
          item?.tags[0]?.split(",").map((i: any) => (
            <div
              className="bg-[#06001b0d] mr-[10px] py-[5px] px-[10px] rounded-[10px]"
              key={i}
            >
              {i}
            </div>
          ))}
      </td>
      <td className="text-[10px]">{moment(item.dateCreated).format("LL")}</td>
      <td className="text-[10px]">
        <div
          className={`
              ${
                item.status === Status.PUBLISHED
                  ? "bg-[#17c0280d] text-[#17C028] rounded-[40px] w-[60%] h-[27px] py-[5px] px-[10px] text-center pt-[5px]"
                  : "bg-[#e8b6061a] text-[#E8B606] rounded-[40px] w-[60%] h-[27px] py-[5px] px-[10px] text-center pt-[5px]"
              }
          `}
        >
          {item.status === Status.PUBLISHED ? "PUBLISHED" : "DRAFT"}
        </div>
      </td>
    </tr>
  ));

  return (
    <>
      <TableHeader
        setSelectedTable={handleTableSelect}
        selectedTable={selectedTable}
        setSearchText={setSearchText}
        searchText={searchText}
        tableTitle={tableTitle}
        blogComp={blogComp}
      />
      {isLoading ? (
        <div className="flex justify-center items-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />{" "}
        </div>
      ) : posts.length < 1 ? (
        <div className="flex justify-center items-center">
          <Empty />{" "}
        </div>
      ) : (
        <table>
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
          <tbody>{tableData}</tbody>
        </table>
      )}
    </>
  );
};

export default TableComponent;
