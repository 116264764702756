import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface ITextAreaProps {
  handleTextChange: (text: string) => void;
  postContent: string;
}

const TextArea: React.FC<ITextAreaProps> = ({
  handleTextChange,
  postContent,
}) => {
  const [editorHtml, seteditorHtml] = useState(postContent);

  const handleChange = (html: any) => {
    seteditorHtml(html);
    handleTextChange(html);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  return (
    <div style={{ marginBottom: "104px" }}>
      <ReactQuill
        onChange={handleChange}
        value={editorHtml}
        modules={modules}
        formats={formats}
        bounds={".TextArea"}
        placeholder="Write Somthing"
        style={{ height: "509px", borderColor: "transparent" }}
      />
    </div>
  );
};

export default TextArea;
