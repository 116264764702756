import { useContext } from "react";
import { UiContext } from "../../context/uiContext";

import { Layout } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import Statistics from "../../components/Blogs/Statistics/Statistics";
import DashboardLayout from "../../components/Dashboard/Layout";
import Sidebar from "../../components/Sidebar/Sidebar";
import Subscriber from '../../components/Subscriber/Subscriber'
import * as Icons from "../../utils/icons/index";

const Subscribers = () => {
  

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <DashboardLayout>
        <Content
          style={{ margin: "0px", background: "#FCFCFC", padding: "50px 24px" }}
        >
          <Subscriber />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          All rights reserved ©{new Date().getFullYear()} Created by Tranzitech
        </Footer>
      </DashboardLayout>
    </Layout>
  );
};

export default Subscribers;
