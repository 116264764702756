import { useEffect, useContext, useState, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Empty } from "antd";

import { UiContext } from "../../context/uiContext";
import Button from "../UiKits/Button";
import Statistics from "../Blogs/Statistics/Statistics";

import * as Icons from "../../utils/icons/index";
import Search from "../UiKits/Search";
import moment from "moment";
import {
  fetchAllSubscribers,
  downloadSubscribers,
} from "../../utils/services/subscribers";
// import { downloadCSV } from "../../utils/helper";

interface ISubscribe {
  id: string;
  email: string;
  subscriptionDate: string;
}

const tableHeader: any = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: "50%",
  },
  {
    title: "Added Date",
    dataIndex: "addedDate",
    key: "addedDate",
    width: "50%",
  },
];

const Subscriber = () => {
  const [search, setSearch] = useState("");
  const [downloadUrl, setDownloadUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { subscribers, getSubscribers } = useContext(UiContext);
  const [allSubscribers, setSubscribers] = useState<ISubscribe[]>(
    subscribers || []
  );

  const { SubscriberIcon } = Icons;

  const fetchSubscribers = useCallback(async (search: string) => {
    setIsLoading(true);
    const payload: any = {
      pageSize: 20,
      pageNumber: 1,
      parameter: {
        query: search,
      },
    };
    try {
      const res = await fetchAllSubscribers(payload);
      setSubscribers(res.data.data.object);
      getSubscribers(res.data.data.object);
    } catch (error) {
      (() =>
        toast.error(
          "There was an error while trying to fetch the subcribers !",
          {
            position: "top-center",
          }
        ))();
      console.log("Error ", error);
    }
    setIsLoading(false);
  }, []);

  const fetchDownloadSubscribers = useCallback(async (search: string) => {
    const payload: any = {
      query: search,
    };

    try {
      const res = await downloadSubscribers(payload);
      setDownloadUrl(res.data.data.url);
    } catch (error) {
      // (() =>
      //   toast.error(
      //     "There was an error while trying to download the subscribers !",
      //     {
      //       position: "top-center",
      //     }
      //   ))();
      console.log("Error ", error);
    }
  }, []);

  useEffect(() => {
    (async () => await fetchSubscribers(search))();
  }, [search, fetchSubscribers]);

  useEffect(() => {
    (async () => await fetchDownloadSubscribers(search))();
  }, [search, fetchDownloadSubscribers]);

  const statistics: any = [
    {
      id: 1,
      title: "Total Subscribers",
      icon: SubscriberIcon,
      total: allSubscribers.length,
    },
  ];

  const handleDownload = () => {
    // downloadCSV(allSubscribers, "subscribers.csv");
    return window.open(downloadUrl);
  };

  const tableHeaders = tableHeader.map((item: any) => (
    <th key={item.key} className="table-header">
      {item.title}
    </th>
  ));

  const filterData =
    allSubscribers &&
    allSubscribers.filter((item) =>
      item.email.toLowerCase().includes(search.toLowerCase())
    );

  const tableData = filterData.map((item) => (
    <tr key={item.id} className="cursor-pointer">
      <td className="text-[12px]">{item.email}</td>
      <td className="text-[10px]">
        {moment(item.subscriptionDate).format("LL")}
      </td>
    </tr>
  ));
  return (
    <div>
      <div className="flex row justify-between mb-[43px]">
        <div>
          <h1 className="text-[24px] font-bold text-[#06001B]">Subscribers</h1>
          <p className="text-[#06001b99] text-[12px]">
            All your subscribers in one place
          </p>
        </div>
        <div>
          <Button
            label="Download CSV"
            btnClass="border border-[#120051] text-[#120051]"
            handleClick={() => handleDownload()}
          />
        </div>
      </div>
      <Statistics statistics={statistics} />

      <div className="flex row justify-between mt-[69px]">
        <h1 className="text-[16px] text-[#06001B] font-bold">
          Recent Subscribers
        </h1>
        <Search searchText={search} setSearchText={setSearch} />
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center">
          {" "}
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />{" "}
        </div>
      ) : tableData.length < 1 ? (
        <div className="flex justify-center items-center">
          <Empty />
        </div>
      ) : (
        <table>
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
          <tbody>{tableData}</tbody>
        </table>
      )}
      {/* <TableComponent
        data={[]}
        setSearchText={setSearch}
        searchText={search}
        handlePostBlog={() => {}}
        setIsEdit={() => {}}
        tableHeader={tableHeader}
        tableTitle="Recent Subscribers"
      /> */}
      <ToastContainer />
    </div>
  );
};

export default Subscriber;
