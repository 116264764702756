import React from "react";

interface InputProps {
  placeholder: string;
  value?: string;
  handleChange?: any;
  loading?: boolean;
  name?: string;
  register?: any;
  type?: any;
  pattern?: any;
  readOnly?: boolean;
  styles?: string;
}

export function Input({
  placeholder,
  value,
  handleChange,
  loading,
  name,
  register,
  type = "text",
  styles,
  pattern,
  readOnly,
}: InputProps): JSX.Element {
  return (
    <input
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      disabled={loading}
      className={`border border-[#06001B] w-full  font-Roboto h-[48px] rounded-[12px] px-2 p-4 focus:border-[#120051] focus:ring-[#120051] focus:outline-none ${styles}`}
      name={name}
      {...register}
      type={type}
      pattern={pattern}
      readOnly={readOnly}
    />
  );
}
