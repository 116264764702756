import { Instance } from "../axios/instance";

export const fetchAllSubscribers = async (payload: any) => {
  const data = await Instance.post("/Subscription/SearchSubscriber", payload);
  return data;
};

export const downloadSubscribers = async (query: string) => {
  const data = await Instance.post("/Subscription/DownloadSubscriber", query);
  return data;
};
