import React from 'react';

const BlogsIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.33301 12.5C2.05967 12.5 1.83301 12.2734 1.83301 12V4.66671C1.83301 1.72671 2.72634 0.833374 5.66634 0.833374H10.333C13.273 0.833374 14.1663 1.72671 14.1663 4.66671V11.3334C14.1663 11.44 14.1663 11.54 14.1597 11.6467C14.1397 11.92 13.893 12.1334 13.6263 12.1134C13.353 12.0934 13.1397 11.8534 13.1597 11.58C13.1663 11.5 13.1663 11.4134 13.1663 11.3334V4.66671C13.1663 2.28671 12.7197 1.83337 10.333 1.83337H5.66634C3.27967 1.83337 2.83301 2.28671 2.83301 4.66671V12C2.83301 12.2734 2.60634 12.5 2.33301 12.5Z'
        fill='#120051'
      />
      <path
        d='M11.333 15.1667H4.66634C3.10634 15.1667 1.83301 13.8933 1.83301 12.3333V11.9C1.83301 10.5733 2.91301 9.5 4.23301 9.5H13.6663C13.9397 9.5 14.1663 9.72667 14.1663 10V12.3333C14.1663 13.8933 12.893 15.1667 11.333 15.1667ZM4.23301 10.5C3.45967 10.5 2.83301 11.1267 2.83301 11.9V12.3333C2.83301 13.3467 3.65301 14.1667 4.66634 14.1667H11.333C12.3463 14.1667 13.1663 13.3467 13.1663 12.3333V10.5H4.23301Z'
        fill='#120051'
      />
      <path
        d='M10.6663 5.16663H5.33301C5.05967 5.16663 4.83301 4.93996 4.83301 4.66663C4.83301 4.39329 5.05967 4.16663 5.33301 4.16663H10.6663C10.9397 4.16663 11.1663 4.39329 11.1663 4.66663C11.1663 4.93996 10.9397 5.16663 10.6663 5.16663Z'
        fill='#120051'
      />
      <path
        d='M8.66634 7.5H5.33301C5.05967 7.5 4.83301 7.27333 4.83301 7C4.83301 6.72667 5.05967 6.5 5.33301 6.5H8.66634C8.93967 6.5 9.16634 6.72667 9.16634 7C9.16634 7.27333 8.93967 7.5 8.66634 7.5Z'
        fill='#120051'
      />
    </svg>
  );
};

export default BlogsIcon;
