import React from 'react';

const SubscriberIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M9.9998 18.9584C9.44147 18.9584 8.8748 18.8167 8.3748 18.5251L3.4248 15.6667C2.4248 15.0834 1.7998 14.0084 1.7998 12.8501V7.1501C1.7998 5.99176 2.4248 4.91676 3.4248 4.33342L8.3748 1.4751C9.3748 0.891764 10.6165 0.891764 11.6248 1.4751L16.5748 4.33342C17.5748 4.91676 18.1998 5.99176 18.1998 7.1501V12.8501C18.1998 14.0084 17.5748 15.0834 16.5748 15.6667L11.6248 18.5251C11.1248 18.8167 10.5581 18.9584 9.9998 18.9584ZM9.9998 2.29175C9.65814 2.29175 9.30814 2.38342 8.9998 2.55842L4.0498 5.41675C3.43314 5.77508 3.0498 6.43343 3.0498 7.1501V12.8501C3.0498 13.5584 3.43314 14.2251 4.0498 14.5834L8.9998 17.4417C9.61647 17.8001 10.3831 17.8001 10.9998 17.4417L15.9498 14.5834C16.5665 14.2251 16.9498 13.5667 16.9498 12.8501V7.1501C16.9498 6.44176 16.5665 5.77508 15.9498 5.41675L10.9998 2.55842C10.6915 2.38342 10.3415 2.29175 9.9998 2.29175Z'
        fill='#120051'
      />
      <path
        d='M10.0003 9.79176C8.58359 9.79176 7.43359 8.64174 7.43359 7.22508C7.43359 5.80841 8.58359 4.65845 10.0003 4.65845C11.4169 4.65845 12.5669 5.80841 12.5669 7.22508C12.5669 8.64174 11.4169 9.79176 10.0003 9.79176ZM10.0003 5.90845C9.27526 5.90845 8.68359 6.50008 8.68359 7.22508C8.68359 7.95008 9.27526 8.54176 10.0003 8.54176C10.7253 8.54176 11.3169 7.95008 11.3169 7.22508C11.3169 6.50008 10.7253 5.90845 10.0003 5.90845Z'
        fill='#120051'
      />
      <path
        d='M13.3337 14.5084C12.992 14.5084 12.7087 14.225 12.7087 13.8834C12.7087 12.7334 11.492 11.7917 10.0003 11.7917C8.50866 11.7917 7.29199 12.7334 7.29199 13.8834C7.29199 14.225 7.00866 14.5084 6.66699 14.5084C6.32533 14.5084 6.04199 14.225 6.04199 13.8834C6.04199 12.0417 7.81699 10.5417 10.0003 10.5417C12.1837 10.5417 13.9587 12.0417 13.9587 13.8834C13.9587 14.225 13.6753 14.5084 13.3337 14.5084Z'
        fill='#120051'
      />
    </svg>
  );
};

export default SubscriberIcon;
